import {MailForm} from '~/components/mail-form'

export default function Index() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-indigo-500">
        <h1 className="text-3xl font-bold">Bloemium</h1>
        <p>Software development &amp; consulting</p>
        <p>
          <MailForm />
        </p>
      </div>
    </div>
  )
}
